import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import projects from "./projects-data.js";
import ProjectCard from "./project-card";
import ProjectModal from "./project-modal";
import vaporwaveSun from "../../vaporwaveSun.png";
import "../../assets/fonts/neon/neonfont.css";

const ProfessionalNeonBanner = ({
  text = "More coming soon",
  baseColor = "#00FFCCFF",
}) => {
  const [flicker, setFlicker] = useState(false);

  useEffect(() => {
    const flickerInterval = setInterval(() => {
      if (Math.random() < 0.2) {
        // 10% chance to flicker
        setFlicker(true);
        setTimeout(() => setFlicker(false), 50 + Math.random() * 100);
      }
    }, 100);

    return () => clearInterval(flickerInterval);
  }, []);

  const getColor = (opacity) => {
    const r = parseInt(baseColor.slice(1, 3), 16);
    const g = parseInt(baseColor.slice(3, 5), 16);
    const b = parseInt(baseColor.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  return (
    <div className="flex justify-center items-center my-10">
      <div
        className={`relative text-2xl font-semibold py-3 px-6 rounded-md transition-all duration-100 ${
          flicker ? "opacity-60" : "opacity-100"
        }`}
        style={{
          color: "white",
          textShadow: `0 0 7px ${getColor(0.8)}, 0 0 10px ${getColor(
            0.8
          )}, 0 0 21px ${getColor(0.6)}`,
          backgroundColor: "transparent",
          border: `2px solid ${baseColor}`,
          boxShadow: `0 0 7px ${getColor(0.8)}, 0 0 10px ${getColor(
            0.4
          )}, 0 0 21px ${getColor(0.2)}, inset 0 0 7px ${getColor(
            0.8
          )}, inset 0 0 10px ${getColor(0.4)}, inset 0 0 21px ${getColor(0.2)}`,
          fontFamily: "'Roboto', sans-serif",
          letterSpacing: "0.05em",
        }}
      >
        {text}
      </div>
    </div>
  );
};

const ProjectsSection = () => {
  const { t } = useTranslation();
  const [selectedProject, setSelectedProject] = useState(null);

  return (
    <div className="">
      <div className="max-w-7xl mx-auto">
        <img
          src={vaporwaveSun}
          style={{
            zIndex: -20,
          }}
          className="w-[10rem] h-[10rem] m-auto my-[20rem] mb-[5rem]"
          alt="Vaporwave Sun"
        />
        <motion.h2
          className="text-5xl font-light text-center mb-[10rem] text-white dark:text-white"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          style={{
            textShadow: `0 0 5px blue, 0 0 10px blue, 0 0 15px blue, 0 0 20px blue`,
            color: "#ffffff",
            fontFamily: "Neon",
          }}
        >
          {t("projectsTitle")}
        </motion.h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-0 px-0">
          {projects.map((project, index) => (
            <ProjectCard
              key={project.id}
              project={project}
              index={index}
              model={project.model}
              totalProjects={projects.length}
              onClick={() => {
                console.log("ProjectCard clicked:", project.id);
                setSelectedProject(project);
              }}
            />
          ))}
        </div>
        <ProfessionalNeonBanner></ProfessionalNeonBanner>
        <div className="my-[20rem]" style={{ zIndex: -0 }}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
            <rect width="100%" height="100%" fill="#000000" opacity="0" />
            <g fill="#ffffff" opacity="0.3">
              <circle cx="579" cy="438" r="3.4" />
              <circle cx="9" cy="1569" r="3.1" />
              <circle cx="447" cy="764" r="3.3" />
              <circle cx="404" cy="998" r="3.3" />
              <circle cx="930" cy="992" r="3.3" />
              <circle cx="181" cy="431" r="3.0" />
              <circle cx="810" cy="734" r="3.5" />
              <circle cx="1" cy="1442" r="3.3" />
              <circle cx="278" cy="508" r="3.3" />
              <circle cx="61" cy="141" r="3.2" />
              <circle cx="656" cy="517" r="3.0" />
              <circle cx="547" cy="314" r="3.3" />
              <circle cx="301" cy="281" r="3.1" />
              <circle cx="139" cy="1976" r="3.4" />
              <circle cx="363" cy="678" r="3.3" />
              <circle cx="153" cy="728" r="3.0" />
              <circle cx="791" cy="1481" r="3.3" />
              <circle cx="962" cy="752" r="3.0" />
              <circle cx="7" cy="348" r="3.2" />
              <circle cx="64" cy="110" r="3.0" />
              <circle cx="312" cy="869" r="3.2" />
              <circle cx="648" cy="1395" r="3.1" />
              <circle cx="842" cy="818" r="3.0" />
              <circle cx="814" cy="973" r="3.1" />
              <circle cx="383" cy="886" r="3.3" />
              <circle cx="308" cy="787" r="3.1" />
              <circle cx="944" cy="160" r="3.0" />
              <circle cx="30" cy="436" r="3.5" />
              <circle cx="169" cy="128" r="3.3" />
              <circle cx="448" cy="61" r="3.3" />
            </g>
            <g fill="#ffffff" opacity="0.7">
              <circle cx="160" cy="1485" r="2.7" />
              <circle cx="458" cy="1067" r="2.9" />
              <circle cx="201" cy="1350" r="2.9" />
              <circle cx="330" cy="297" r="2.7" />
              <circle cx="18" cy="1165" r="2.8" />
              <circle cx="898" cy="1460" r="2.7" />
              <circle cx="228" cy="88" r="2.8" />
              <circle cx="513" cy="1697" r="2.7" />
              <circle cx="576" cy="832" r="2.8" />
              <circle cx="372" cy="1152" r="2.8" />
              <circle cx="518" cy="1241" r="2.8" />
              <circle cx="303" cy="1541" r="2.9" />
              <circle cx="456" cy="1732" r="2.8" />
              <circle cx="453" cy="1715" r="2.7" />
              <circle cx="537" cy="442" r="2.7" />
              <circle cx="489" cy="727" r="2.8" />
              <circle cx="732" cy="97" r="2.8" />
              <circle cx="766" cy="477" r="2.9" />
              <circle cx="24" cy="1257" r="2.9" />
              <circle cx="620" cy="1348" r="2.7" />
              <circle cx="481" cy="1286" r="2.8" />
              <circle cx="429" cy="1733" r="2.7" />
              <circle cx="545" cy="1550" r="2.7" />
              <circle cx="780" cy="1473" r="2.8" />
              <circle cx="966" cy="1187" r="2.9" />
              <circle cx="548" cy="1919" r="2.7" />
              <circle cx="134" cy="1658" r="2.9" />
              <circle cx="787" cy="1459" r="2.8" />
              <circle cx="65" cy="1087" r="2.8" />
              <circle cx="741" cy="980" r="2.8" />
            </g>
          </svg>
        </div>
      </div>
      <AnimatePresence mode="wait">
        {selectedProject && (
          <ProjectModal
            key="modal"
            project={selectedProject}
            onClose={() => {
              console.log("Closing modal");
              setSelectedProject(null);
            }}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default ProjectsSection;
