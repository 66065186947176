import React from 'react';
import { useTranslation } from 'react-i18next';
import { Globe } from 'lucide-react';

const LanguageSwitch = ({ color = "#00ffff" }) => {
  const { i18n } = useTranslation();
  
  const toggleLanguage = () => {
    i18n.changeLanguage(i18n.language === 'en' ? 'it' : 'en');
  };
  
  const isItalian = i18n.language === 'it';
  
  return (
    <button
      onClick={toggleLanguage}
      className="fixed top-4 right-4 z-50 rounded-full p-0.5 shadow-lg hover:shadow-xl transition-all duration-300 ease-in-out group focus:outline-none focus:ring-2 focus:ring-opacity-50"
      style={{ '--color': color }}
      aria-label={`Switch to ${isItalian ? 'English' : 'Italian'}`}
    >
      <div 
        className="rounded-full p-2 flex items-center space-x-2 border-2"
        style={{
          backgroundColor: `${color}1A`, // 10% opacity
          borderColor: color
        }}
      >
        <Globe style={{ color: color }} className="w-5 h-5" />
        <span style={{ color: color }} className="font-medium text-sm pr-1">
          {isItalian ? 'IT' : 'EN'}
        </span>
      </div>
    </button>
  );
};

export default LanguageSwitch;