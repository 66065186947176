import React, { useState, useEffect, useRef, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import PresentationSection from "./PresentationSection";
import ProjectsSection from "./sections/projectsSection/projects-section.js";
import ContactSection from "./ContactSection";
import LanguageSwitch from "./elements/LanguageSwitch.js";
import { useTranslation } from "react-i18next";
import font from "./assets/fonts/google/googlesans.css";

const LoadingPage = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-900 z-50">
    <div className="text-center">
      <div className="w-16 h-16 border-4 mx-auto border-purple-500 border-t-transparent rounded-full animate-spin mb-4"></div>
      <p className="text-xl font-semibold text-gray-200">
        This website is a work in progress
      </p>
    </div>
  </div>
);

const lerp = (start, end, t) => start.map((s, i) => s + (end[i] - s) * t);

export default function App() {
  const { t, i18n } = useTranslation();
  const [currentSection, setCurrentSection] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [colors, setColors] = useState({
    background: [0, 0, 0],
    low: [0.1, 0.1, 0.8],
    mid: [1, 0.0, 1],
    high: [1.0, 0.1, 0.1],
    language: [0,0,0]
  });

  const sectionRefs = useRef([]);
  const currentSectionRef = useRef(0);
  const animationRef = useRef(null);
  const startTimeRef = useRef(null);
  const prevColorsRef = useRef(colors);

  const sections = [
    { id: "presentation", title: "Home", component: PresentationSection },
    { id: "projects", title: "Projects", component: ProjectsSection },
    { id: "contact", title: "Contact", component: ContactSection },
  ];

  const sectionColors = [
    { background: [0, 0, 0], low: [0.1, 0.1, 0.8], mid: [1, 0.0, 1], high: [1.0, 0.1, 0.1] , language:[1,1,1]},
    { background: [0.0, 0.0, 0.1], low: [0, 1.0, 1.0], mid: [0.0, 0.0, 1.0], high: [0, 1.0, 1.0] , language:[0.7,1,1]},
    { background: [1, 1, 1], low: [0.1, 0.1, 0.1], mid: [0.8, 0.8, 0.8], high: [0.8, 0.8, 0.8] , language:[0,0,0]},
  ];

  const toggleLanguage = () => {
    i18n.changeLanguage(i18n.language === "en" ? "it" : "en");
  };

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY + window.innerHeight / 2;
    let newCurrentSection = 0;

    sectionRefs.current.forEach((ref, index) => {
      if (ref && scrollPosition >= ref.offsetTop) {
        newCurrentSection = index;
      }
    });

    if (newCurrentSection !== currentSectionRef.current) {
      currentSectionRef.current = newCurrentSection;
      setCurrentSection(newCurrentSection);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const targetColors = sectionColors[currentSection];
    const duration = 500; // Animation duration in milliseconds

    const animateColors = (currentTime) => {
      if (startTimeRef.current === null) {
        startTimeRef.current = currentTime;
        prevColorsRef.current = colors;
      }

      const elapsed = currentTime - startTimeRef.current;
      const progress = Math.min(elapsed / duration, 1);

      setColors({
        background: lerp(prevColorsRef.current.background, targetColors.background, progress),
        low: lerp(prevColorsRef.current.low, targetColors.low, progress),
        mid: lerp(prevColorsRef.current.mid, targetColors.mid, progress),
        high: lerp(prevColorsRef.current.high, targetColors.high, progress),
        language: lerp(prevColorsRef.current.language, targetColors.language, progress),
      });

      if (progress < 1) {
        animationRef.current = requestAnimationFrame(animateColors);
      } else {
        startTimeRef.current = null;
      }
    };

    // Cancel any ongoing animation before starting a new one
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }

    animationRef.current = requestAnimationFrame(animateColors);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [currentSection]);

  if (isLoading) {
    return <LoadingPage />;
  }

  const toRGB = (value) => Math.max(0, Math.min(255, Math.round(value * 255)));
  const bgColorStyle = `rgb(${toRGB(colors.background[0])}, ${toRGB(colors.background[1])}, ${toRGB(colors.background[2])})`;
  const languageColorStyle = `rgb(${toRGB(colors.language[0])}, ${toRGB(colors.language[1])}, ${toRGB(colors.language[2])})`;

  return (
    <div className="min-h-screen" style={{ backgroundColor: bgColorStyle }}>
      <LanguageSwitch onToggle={toggleLanguage} color={languageColorStyle}/>
      {sections.map((section, index) => (
        <div key={section.id} ref={(el) => (sectionRefs.current[index] = el)}>
          <section.component
            color={colors.background}
            colorLow={colors.low}
            colorMid={colors.mid}
            colorHigh={colors.high}
          />
        </div>
      ))}
    </div>
  );
}