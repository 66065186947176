import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Github, Linkedin, Mail, ExternalLink } from 'lucide-react';

const ContactCard = ({ title, icon: Icon, link, color, description }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="block h-full"
    >
      <div 
        className="relative overflow-hidden rounded-2xl p-7 h-full bg-gray-50 border border-gray-100"
      >
        <div className="relative">
          <div 
            className="w-14 h-14 rounded-xl flex items-center justify-center mb-5"
            style={{ 
              backgroundColor: `${color}08`,
            }}
          >
            <Icon 
              size={26} 
              style={{ color }}
              strokeWidth={1.5}
            />
          </div>
          
          <h3 
            className="text-xl font-semibold mb-3 text-gray-800"
          >
            {title}
          </h3>
          <p className="text-gray-600 mb-5 text-[15px] leading-relaxed">
            {description}
          </p>
          
          <div 
            className="inline-flex items-center text-sm font-medium text-gray-600"
          >
            <span className="mr-2 text-[13px] uppercase tracking-wide font-semibold">
              Connect
            </span>
            <ExternalLink size={14} strokeWidth={2} />
          </div>
        </div>
      </div>
    </a>
  );
};

const SectionTitle = ({ title, subtitle }) => (
  <motion.div
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    className="text-center mb-12"
  >
    <h2 className="text-4xl md:text-5xl font-bold text-gray-800 mb-4">
      {title}
    </h2>
    <p className="text-gray-600 text-lg max-w-md mx-auto">
      {subtitle}
    </p>
  </motion.div>
);

const ContactGrid = ({ contacts }) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full max-w-5xl px-6 md:px-4"
    >
      {contacts.map((contact) => (
        <motion.div
          key={contact.id}
          variants={itemVariants}
          className="relative"
        >
          <ContactCard {...contact} />
        </motion.div>
      ))}
    </motion.div>
  );
};

const ContactSection = () => {
  const { t } = useTranslation();

  const contacts = [
    {
      id: 'github',
      title: 'GitHub',
      icon: Github,
      link: 'https://github.com/blackhole404',
      color: '#2D333B',
      description: 'Check out my open source projects'
    },
    {
      id: 'linkedin',
      title: 'LinkedIn',
      icon: Linkedin,
      link: 'https://linkedin.com/in/angelo-germinario',
      color: '#0066A1',
      description: 'Connect with me professionally'
    },
    {
      id: 'email',
      title: 'Email',
      icon: Mail,
      link: 'mailto:angelogerminario1@gmail.com',
      color: '#D93025',
      description: 'Send me a message'
    }
  ];

  return (
    <div className="min-h-[100dvh] h-[100dvh] w-full flex flex-col items-center justify-center">
      <div className="flex-1 w-full flex flex-col items-center justify-center">
        <SectionTitle
          title={t('contactsTitle')}
          subtitle="Let's connect and create something amazing together"
        />
        <ContactGrid contacts={contacts} />
      </div>
    </div>
  );
};

export default ContactSection;