const projects = [
  {
    id: 1,
    title: "Smartwatch",
    image: "gradient2.png",
    fullDescription: "This is a full description of the amazing project...",
    technologies: ["C++", "PCB Design", "3D Design", "Embedded", "UI/UX design"],
    hasExternalLink: true,
    externalLink: "https://example.com",
    githubLink: "https://github.com/username/repo",
    model: "./watch.gltf"
  },
  {
    id: 2,
    title: "Power supply",
    image: "gradient2.png",
    fullDescription: "This is a full description of the amazing project...",
    technologies: ["Electronics", "3D Printing", "3D Design"],
    hasExternalLink: true,
    externalLink: "https://example.com",
    githubLink: "https://github.com/username/repo",
  },
  {
    id: 3,
    title: "Smart Lights",
    image: "gradient2.png",
    fullDescription: "This is a full description of the amazing project...",
    technologies: ["Alexa", "Embedded", "C++"],
    hasExternalLink: true,
    externalLink: "https://example.com",
    githubLink: "https://github.com/username/repo",
    model: "./light.gltf"
  },
];

export default projects;